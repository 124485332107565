<template>
  <v-container fluid>
    <running-dialog
      message="Saving changes. Please wait..."
      :showDialog.sync="saving"
    ></running-dialog>
    <success-dialog
      message="Updated successfully"
      :showDialog.sync="updatedSuccessfully"
    ></success-dialog>
    <error-dialog
      message="Error! Entity not updated!"
      :showDialog.sync="updateFailed"
    ></error-dialog>
    <delete-group-dialog
      :showDialog.sync="showDeleteGroupDialog"
      :groupToDelete.sync="groupToDelete"
    ></delete-group-dialog>
    <v-card class="ml-3 px-2 elevation-0">
      <v-row>
        <v-col cols="12" md="8">
          <v-card-title>Modify Entity</v-card-title>
        </v-col>
        <v-col
          v-if="
            modifiedEntity !== null &&
              modifiedEntity !== undefined &&
              modifiedEntity.type == 'ACCOUNT_GROUP'
          "
          cols="12"
          md="4"
        >
          <v-row>
            <v-col cols="12">
              <v-btn
                :color="colorTheme.button"
                dark
                @click="deleteGroup(modifiedEntity)"
              >
                Delete Group
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-form ref="form" v-model="valid">
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Name"
                v-model="formEdit.name"
                :rules="rules.name"
                required
                dense
                filled
                :color="colorTheme.textField"
                :disabled="disableEditName"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                label="Entity Type"
                v-model="formEdit.type"
                dense
                outlined
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <span>
                <v-text-field
                  label="External ID"
                  outlined
                  readonly
                  disabled
                  dense
                  :color="colorTheme.textField"
                >
                  <template v-slot:prepend-inner>
                    <cell-clipboard :text="formEdit.xref" />
                  </template>
                </v-text-field>
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="8">
              <v-text-field
                label="Parent Entity"
                v-model="parent.name"
                :placeholder="noDataPlaceholder"
                dense
                outlined
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                label="Parent Type"
                v-model="parent.type"
                :placeholder="noDataPlaceholder"
                dense
                outlined
                disabled
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <v-switch
                label="New"
                v-model="formEdit.isNew"
                :color="colorTheme.toggle"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row class="mt-5">
            <v-col cols="12" md="8">
              <v-text-field
                label="Updated At"
                v-model="formEdit.updatedAt"
                :placeholder="noDataPlaceholder"
                dense
                outlined
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                label="Updated By"
                v-model="formEdit.updatedBy"
                :placeholder="noDataPlaceholder"
                dense
                outlined
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="8">
              <v-text-field
                label="Created At"
                v-model="formEdit.createdAt"
                :placeholder="noDataPlaceholder"
                outlined
                dense
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                label="Created By"
                v-model="formEdit.createdBy"
                :placeholder="noDataPlaceholder"
                dense
                outlined
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-card-actions v-if="!disableButtons">
            <v-spacer></v-spacer>
            <v-btn
              :style="colorTheme.textColor"
              :color="colorTheme.buttonWhite"
              @click="cancel"
            >
              Cancel
            </v-btn>
            <v-btn dark :color="colorTheme.button" @click="save">
              Save Changes
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

import DeleteGroupDialog from "@/components/account-groups/dialogs/DeleteGroupDialog.vue";
import CellClipboard from "@/components/common/CellClipboard.vue";
import RunningDialog from "@/components/dialogs/RunningDialog.vue";
import SuccessDialog from "@/components/dialogs/SuccessDialog.vue";
import ErrorDialog from "@/components/dialogs/ErrorDialog.vue";

export default {
  components: {
    DeleteGroupDialog,
    CellClipboard,
    RunningDialog,
    SuccessDialog,
    ErrorDialog,
  },
  data: () => ({
    valid: false,
    formOriginal: null,
    parent: {
      id: "",
      name: "",
      type: ""
    },
    formEdit: {
      name: "",
      type: "",
      xref: "",
      isNew: "",
      createdAt: "",
      createdBy: "",
      updatedAt: "",
      updatedBy: ""
    },
    rules: {
      name: [(v) => !!v || "Name is required"]
    },
    noDataPlaceholder: "No Data",
    groupToDelete: null,
    showDeleteGroupDialog: false,
    saving: false,
    updatedSuccessfully: false,
    updateFailed: false
  }),
  computed: {
    ...mapState("directoryNavigation", ["navigation"]),
    ...mapState("modifyEntity", ["modifiedEntity", "parentEntity"]),
    ...mapState("modifyEntityGroup", ["groupModified", "groupAccounts"]),
    ...mapState("userConfig", ["colorTheme"]),
    formIsNotChanged() {
      if (this.formOriginal && this.formEdit) {
        const original = this.formOriginal;
        const modified = this.formEdit;

        let notChanged = true;
        if (
          original.name !== modified.name ||
          original.isNew !== modified.isNew
        ) {
          notChanged = false;
        }
        if (original.xref === null) {
          original.xref = "";
        }
        if (modified.xref === null) {
          modified.xref = "";
        }
        if (original.xref !== modified.xref) {
          notChanged = false;
        }

        return notChanged;
      }
      return true;
    },
    disableEditName() {
      return !this.formEdit.type.includes("GROUP");
    },
    disableButtons() {
      if (!this.valid) {
        return true;
      } else {
        if (!this.formIsNotChanged) {
          return this.formIsNotChanged;
        } else {
          return !this.groupModified;
        }
      }
    }
  },
  watch: {
    modifiedEntity() {
      this.formEdit = Object.assign({}, this.modifiedEntity);
      this.formOriginal = Object.assign({}, this.modifiedEntity);
    },
    parentEntity() {
      this.parent = Object.assign({}, this.parentEntity);
    }
  },
  methods: {
    ...mapMutations("modifyEntityGroup", ["setGroupModified"]),
    ...mapActions("modifyEntity", ["updateEntity"]),
    ...mapActions("modifyEntityGroup", ["loadAccounts"]),
    deleteGroup(group) {
      this.groupToDelete = group;
      this.showDeleteGroupDialog = true;
    },
    async save() {
      if (this.validate()) {
        try {
          this.saving = true;
          let payload = {
            id: this.modifiedEntity.id,
            name: this.formEdit.name,
            type: this.formEdit.type,
            xref: this.formEdit.xref,
            isNew: this.formEdit.isNew
          };
          if (payload.type === "ACCOUNT_GROUP") {
            let accounts = this.groupAccounts.map((a) => a.id);
            payload.accounts = accounts;
          }
          let result = await this.updateEntity(payload);
          this.saving = false;

          if (result) {
            this.updatedSuccessfully = true;
          } else {
            this.updateFailed = true;
          }

          const newName = this.formEdit.name;
          const indexOfLast = this.navigation.length - 1;
          const id = this.navigation[indexOfLast].id;
          if (id == this.modifiedEntity.id)
            this.navigation[indexOfLast].text = newName;

          this.formEdit = Object.assign({}, this.modifiedEntity);
        } catch (err) {
          this.saving = false;
          console.log(err);
        }
      }
    },
    async cancel() {
      this.formEdit = Object.assign({}, this.modifiedEntity);
      this.setGroupModified(false);
      await this.loadAccounts();
    },
    validate() {
      return this.$refs.form.validate();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    resetForm() {
      this.$refs.form.reset();
    }
  }
};
</script>
