<template>
  <div>
    <error-dialog
      message="Error! Try again."
      :showDialog.sync="showErrorDialog"
    ></error-dialog>
    <error-dialog
      :message="addUpdateErrorMessage"
      :showDialog.sync="showAddUpdateErrorDialog"
    ></error-dialog>
    <error-dialog
      message="Error. Cannot find funded date."
      :showDialog.sync="showStartDateErrorDialog"
    ></error-dialog>
    <confirmation-dialog
      :showDialog.sync="showChoiceDialog"
      :message="choiceMessage"
      @confirm="updateAttribute"
      @cancel="closeChoiceDialog"
    ></confirmation-dialog>
    <info-dialog
      message="Duplicate attribute! Change name or value."
      :showDialog.sync="showDuplicateDialog"
    ></info-dialog>
    <v-dialog v-model="show" persistent max-width="750px" @keydown.esc="cancel">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ addOrUpdateLabel }} Entity Specific Property
          </span>
          <span class="right-corner">
            <v-icon @click="cancel" class="exit-icon"> mdi-close </v-icon>
          </span>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="validForm">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-switch
                    v-if="!isModification"
                    label="Updateable"
                    v-model="editedItem.isUpdateable"
                    :color="colorTheme.toggle"
                  ></v-switch>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="Property Name"
                    v-model="editedItem.attributeName"
                    :rules="rules.attributeName"
                    required
                    :disabled="isModification"
                    :color="colorTheme.textField"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-layout justify-center v-if="valueSelector === 'Date'">
                    <v-date-picker
                      v-if="editedItem.attributeName === 'Billing Start Date'"
                      v-model="editedItem.attributeValue"
                      :min="minDate"
                      :disabled="disabledStartDate"
                      required
                      scrollable
                      :color="colorTheme.datePicker"
                    ></v-date-picker>
                    <v-date-picker
                      v-else
                      v-model="editedItem.attributeValue"
                      required
                      scrollable
                      :color="colorTheme.datePicker"
                    ></v-date-picker>
                  </v-layout>
                  <v-select
                    v-else-if="valueSelector === 'EnumeratedValue'"
                    label=""
                    v-model="editedItem.attributeValue"
                    :items="dropdownValues"
                    item-text="displayName"
                    item-value="value"
                    :color="colorTheme.dropDown"
                    :item-color="colorTheme.dropDownItem"
                  ></v-select>
                  <v-text-field
                    v-else-if="
                      valueSelector === 'String' || valueSelector === 'Guid'
                    "
                    label="Property Value"
                    v-model="editedItem.attributeValue"
                    :rules="rules.attributeValue"
                    required
                    :color="colorTheme.textField"
                  ></v-text-field>
                  <account-search
                    v-else-if="valueSelector === 'Search'"
                    :selectNew="selectNew"
                    :searchLabel="searchLabel"
                    :theme="colorTheme"
                  ></account-search>
                  <v-text-field
                    v-else
                    label="Error"
                    v-model="editedItem.attributeValue"
                    :rules="rules.attributeValue"
                    required
                    :color="colorTheme.textField"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :style="colorTheme.textColor"
            :color="colorTheme.buttonWhite"
            @click="cancel"
          >
            Cancel
          </v-btn>
          <v-btn
            :dark="validForm && !emptyValue"
            :color="colorTheme.button"
            @click="confirm"
            :disabled="!validForm || emptyValue"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getRulesForAttribute } from "@/utils/entity-attributes.js";
import { mapActions, mapMutations, mapState } from "vuex";
import ErrorDialog from "@/components/dialogs/ErrorDialog.vue";
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog.vue";
import InfoDialog from "@/components/dialogs/InfoDialog.vue";
import AccountSearch from "@/components/common/AccountSearch.vue";

export default {
  components: {
    ErrorDialog,
    ConfirmationDialog,
    InfoDialog,
    AccountSearch
  },
  props: ["showDialog", "attributeSelected"],
  emits: ["update:showDialog", "update:attributeSelected"],
  data: () => ({
    disabledStartDate: false,
    accWithMinDate: null,
    showAddUpdateErrorDialog: false,
    showStartDateErrorDialog: false,
    addUpdateErrorMessage: "",
    choiceMessage: "",
    showChoiceDialog: false,
    showDuplicateDialog: false,
    showErrorDialog: false,
    validForm: false,
    editedItem: {
      attributeName: "",
      attributeValue: "",
      isUpdateable: false
    },
    rules: {
      attributeName: [v => !!v || "Attribute Name is required"],
      attributeValue: [v => !!v || "Attribute Value is required"]
    },
  }),
  computed: {
    ...mapState("modifyEntity", ["modifiedEntity"]),
    ...mapState("entityAttributes", [
      "modifiedEntityAttributes",
      "allowedValuesForAttributes"
    ]),
    ...mapState("userConfig", ["colorTheme"]),
    show() {
      return this.showDialog;
    },
    isModification() {
      return this.attributeSelected !== null;
    },
    addOrUpdateLabel() {
      return this.isModification ? "Update" : "Add";
    },
    valueSelector() {
      let type = null;
      if (this.modifiedEntity) {
        type = this.modifiedEntity.type;
      }
      let rules = getRulesForAttribute(
        this.allowedValuesForAttributes,
        this.editedItem.attributeName,
        type
      );
      let dataType = "String";
      if (rules) {
        dataType = rules.dataType;
      }
      return dataType;
    },
    emptyValue() {
      return (
        this.editedItem.attributeValue === undefined ||
        this.editedItem.attributeValue === null ||
        this.editedItem.attributeValue.length === 0
      );
    },
    dropdownValues() {
      let type = null;
      if (this.modifiedEntity) {
        type = this.modifiedEntity.type;
      }
      let rules = getRulesForAttribute(
        this.allowedValuesForAttributes,
        this.editedItem.attributeName,
        type
      );
      if (rules) {
        let allowedValues = rules.allowedValues;
        if (allowedValues.length > 0) {
          let attributeValues = [];
          allowedValues.forEach(attrValue => {
            attributeValues.push({ displayName: attrValue, value: attrValue });
          });
          return attributeValues;
        }
      }
      return [{ displayName: "Error", value: "Error" }];
    },
    searchLabel() {
      if (this.editedItem.attributeValue) {
        return "Current: " + this.editedItem.attributeValue;
      } else {
        return "Search accounts";
      }
    },
    minDate() {
      if (this.accWithMinDate === null || this.accWithMinDate.minDate === null) {
        return this.attributeSelected.attributeValue;
      }
      return this.accWithMinDate.minDate;
    },
  },
  watch: {
    attributeSelected(val) {
      this.setModifiedAttribute(val);
    },
    valueSelector() {
      if (!this.isModification) {
        this.editedItem.attributeValue = "";
      }
    },
  },
  methods: {
    ...mapMutations("searchEntities", ["cancelSearch"]),
    ...mapActions("entityAttributes", [
      "addEntityAttribute",
      "updateEntityAttribute",
      "getMinStartDateForAccount",
    ]),
    async setModifiedAttribute(attribute) {
      if (this.isModification) {
        if (attribute !== null) {
          this.editedItem.attributeName = this.attributeSelected.attributeName;
          this.editedItem.attributeValue = this.attributeSelected.attributeValue;
          this.editedItem.isUpdateable = this.attributeSelected.isUpdateable;
          if (attribute.attributeName === "Billing Start Date") {
            await this.setMinStartDate();
          }
        } else {
          this.showErrorDialog = true;
          this.cancel();
        }
      }
    },
    async setMinStartDate() {
      this.disabledStartDate = true;
      this.accWithMinDate = null;
      let accountId = this.attributeSelected.billingEntityId;
      let result = await this.getMinStartDateForAccount(accountId);
      if (result) {
        this.accWithMinDate = result;
        this.disabledStartDate = false;
      } else {
        this.showStartDateErrorDialog = true;
        this.cancel();
      }
    },
    checkIfAttributeExists() {
      let attributeNames = this.modifiedEntityAttributes.map(
        attr => attr.attributeName
      );
      let newAttrName = this.editedItem.attributeName;
      if (attributeNames.includes(newAttrName)) {
        //There is attribute with that attribute name

        let type = null;
        if (this.modifiedEntity) {
          type = this.modifiedEntity.type;
        }
        let rules = getRulesForAttribute(
          this.allowedValuesForAttributes,
          this.editedItem.attributeName,
          type
        );
        if (rules && rules.allowMultiple) {
          return "Add";
        } else {
          let oldAttribute = this.modifiedEntityAttributes.filter(
            attr => attr.attributeName == newAttrName
          );
          let oldAttrValue = oldAttribute[0].attributeValue;
          if (
            oldAttrValue !== undefined &&
            oldAttrValue !== null &&
            oldAttrValue.length > 0
          ) {
            // There is already that attribute with some value, ask the user for update
            return "Ask";
          } else {
            // There is already that attribute with no value, update value
            return "Update";
          }
        }
      } else {
        // There is no that attribute, add it
        return "Add";
      }
    },
    closeChoiceDialog() {
      this.showChoiceDialog = false;
      this.choiceMessage = "";
    },
    async addAttribute() {
      let result = await this.addEntityAttribute({
        attributeName: this.editedItem.attributeName,
        attributeValue: this.editedItem.attributeValue,
        isUpdateable: this.editedItem.isUpdateable
      });
      if (result === "OK") {
        this.cancel();
      } else {
        this.showAddUpdateErrorDialog = true;
        this.addUpdateErrorMessage = result;
      }
    },
    getAttribute(attrName) {
      let attribute = this.modifiedEntityAttributes.filter(
        attr => attr.attributeName == attrName
      );
      return attribute[0];
    },
    async updateAttribute() {
      let id = null;
      let isUpdateable = false;
      if (this.isModification) {
        id = this.attributeSelected.id;
        isUpdateable =
          this.editedItem.isUpdateable !== null
            ? this.editedItem.isUpdateable
            : false;
      } else {
        let attribute = this.getAttribute(this.editedItem.attributeName);
        id = attribute.id;
        isUpdateable = attribute.isUpdateable;
      }
      let attrName = this.editedItem.attributeName;
      let attrValue = this.editedItem.attributeValue;
      let result = await this.updateEntityAttribute({
        id: id,
        attributeName: attrName,
        attributeValue: attrValue,
        isUpdateable: isUpdateable
      });
      if (result === "OK") {
        this.cancel();
      } else {
        this.showAddUpdateErrorDialog = true;
        this.addUpdateErrorMessage = result;
      }
    },
    async confirm() {
      let duplicate = false;
      this.modifiedEntityAttributes.forEach(attribute => {
        if (
          attribute.attributeName == this.editedItem.attributeName &&
          attribute.attributeValue == this.editedItem.attributeValue
        ) {
          duplicate = true;
        }
      });

      if (this.validate()) {
        if (this.isModification) {
          await this.updateAttribute();
        } else {
          if (duplicate) {
            this.showDuplicateDialog = true;
          } else {
            let choice = this.checkIfAttributeExists();
            switch (choice) {
              case "Add":
                await this.addAttribute();
                break;
              case "Update":
                await this.updateAttribute();
                break;
              case "Ask":
                this.showChoiceDialog = true;
                this.choiceMessage =
                  "There is already attribute " +
                  this.editedItem.attributeName +
                  ", do you want to update it to new value?";
                break;
              default:
                this.showErrorDialog = true;
            }
          }
        }
      } else {
        this.showErrorDialog = true;
      }
    },
    cancel() {
      this.reset();
      if (this.isModification) {
        this.$emit("update:attributeSelected", null);
      }
      this.$emit("update:showDialog", false);
    },
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
      this.editedItem.attributeName = "";
      this.editedItem.attributeValue = "";
      this.editedItem.isUpdateable = false;
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    selectNew(val) {
      if (val === undefined || val === null || val.name === null) {
        this.editedItem.attributeValue = null;
      } else {
        this.editedItem.attributeValue = val.name;
      }
    },
  }
};
</script>
