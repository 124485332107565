<template>
  <v-container fluid>
    <error-dialog
      message="Error deleting attribute!"
      :showDialog.sync="showErrorDialog"
    ></error-dialog>
    <error-dialog
      message="Error! Reload to avoid problems"
      :showDialog.sync="showAllowedValuesError"
    ></error-dialog>
    <v-row align="start" align-content="start" justify="start">
      <v-col cols="6">
        <entity-modifications></entity-modifications>
      </v-col>
      <v-col cols="6">
        <advisory-firm-properties
          v-if="advisoryFirm"
        ></advisory-firm-properties>
        <entity-specific-properties
          @selected-for-delete="selectedForDelete"
        ></entity-specific-properties>
        <group-accounts-selector
          v-if="
            modifiedEntity !== null &&
              modifiedEntity !== undefined &&
              modifiedEntity.type == 'ACCOUNT_GROUP'
          "
        ></group-accounts-selector>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getRulesForAttribute } from "@/utils/entity-attributes.js";
import { mapActions, mapMutations, mapState } from "vuex";
import EntitySpecificProperties from "@/components/add-update-entity/EntitySpecificProperties.vue";
import EntityModifications from "@/components/add-update-entity/EntityModifications.vue";
import GroupAccountsSelector from "@/components/account-groups/GroupAccountsSelector.vue";
import ErrorDialog from "@/components/dialogs/ErrorDialog.vue";
import AdvisoryFirmProperties from "@/components/add-update-entity/AdvisoryFirmProperties.vue";

export default {
  components: {
    EntityModifications,
    EntitySpecificProperties,
    GroupAccountsSelector,
    ErrorDialog,
    AdvisoryFirmProperties
  },
  props: ["hierarchy", "id"],
  data: () => ({
    showErrorDialog: false
  }),
  computed: {
    ...mapState("modifyEntity", ["modifiedEntity"]),
    ...mapState("entityAttributes", ["allowedValuesForAttributes"]),
    ...mapState("dialogs", ["allowedValuesError"]),
    showAllowedValuesError: {
      get() {
        return this.allowedValuesError;
      },
      set(newValue) {
        this.setAllowedValuesError(newValue);
      }
    },
    advisoryFirm() {
      return (
        this.modifiedEntity && this.modifiedEntity.type === "ADVISORY_FIRM"
      );
    },
  },
  methods: {
    ...mapMutations("dialogs", ["setAllowedValuesError"]),
    ...mapActions("browseEntities", ["setDefaultValues"]),
    ...mapActions("modifyEntity", ["refreshEntities"]),
    ...mapActions("entityAttributes", [
      "deleteEntityAttribute",
      "updateEntityAttribute"
    ]),
    async selectedForDelete(attributeForDeletion) {
      if (attributeForDeletion.id !== null) {
        try {
          let type = null;
          if (this.modifiedEntity) {
            type = this.modifiedEntity.type;
          }
          let rules = getRulesForAttribute(
            this.allowedValuesForAttributes,
            attributeForDeletion.attributeName,
            type
          );
          if (rules && rules.allowEmpty) {
            let result = await this.updateEntityAttribute({
              id: attributeForDeletion.id,
              attributeName: attributeForDeletion.attributeName,
              attributeValue: "",
              isUpdateable: attributeForDeletion.isUpdateable
            });
            if (result === "ERROR") {
              this.showErrorDialog = true;
            }
          } else {
            let result = await this.deleteEntityAttribute({
              id: attributeForDeletion.id
            });
            if (result === "ERROR") {
              this.showErrorDialog = true;
            }
          }
        } catch (err) {
          console.log(err);
          this.showErrorDialog = true;
        }
      } else {
        this.showErrorDialog = true;
      }
    }
  },
  async mounted() {
    let payload = {
      tabName: "ModifyEntity",
      hierarchy: this.hierarchy,
      id: this.id
    };
    let result = await this.setDefaultValues(payload);

    if (result) {
      await this.refreshEntities(payload);
    }
  }
};
</script>
