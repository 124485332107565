function getRulesForAttribute(allowedValues, attributeName, entityType) {
  let rules = allowedValues.filter(
    attr => attr.attributeName == attributeName && attr.entityType == entityType
  );
  if (rules.length > 0) {
    return rules[0];
  } else {
    return null;
  }
}

module.exports = {
  getRulesForAttribute,
};
