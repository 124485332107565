<template>
  <v-container fluid>
    <add-update-entity-attribute
      :showDialog.sync="showAddUpdateEntityAttributeDialog"
      :attributeSelected.sync="attributeSelected"
    ></add-update-entity-attribute>
    <v-card class="ml-3 px-2 elevation-0">
      <v-card-title>Entity Specific Properties</v-card-title>
      <v-container fluid>
        <v-card
          class="px-2 my-3 mb-3 elevation-2"
          v-for="(properties, index) in attributes"
          :key="index"
        >
          <v-card-title>
            <div>
              <div class="text-subtitle-1 font-weight-medium">
                {{ properties.attributeName }}
              </div>
              <div class="text-subtitle-2 font-weight-regular">
                {{ properties.attributeValue }}
              </div>
            </div>
          </v-card-title>
          <v-card-text>
            <v-expand-transition>
              <v-container v-show="showList[index]" class="pl-0 pt-5 ma-0">
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    v-for="(value, key) in properties"
                    v-bind:key="key"
                  >
                    <span>
                      <v-text-field
                        v-if="showField(key)"
                        :label="key"
                        outlined
                        readonly
                        disabled
                        dense
                      >
                        <template v-slot:prepend-inner>
                          <cell-clipboard :text="value" />
                        </template>
                      </v-text-field>
                    </span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-timeline dense>
                      <v-timeline-item
                        small
                        v-for="item in timeline(properties)"
                        :key="item.time"
                        :color="item.color"
                      >
                        <div>
                          <div class="font-weight-normal">
                            {{ item.message }}
                            <strong>
                              {{ item.by }}
                            </strong>
                            @{{ item.time }}
                          </div>
                        </div>
                      </v-timeline-item>
                    </v-timeline>
                  </v-col>
                </v-row>
              </v-container>
            </v-expand-transition>
          </v-card-text>
          <v-card-actions>
            <v-btn text :color="colorTheme.button" @click="toggleShow(index)">
              Show {{ showList[index] ? "Less" : "More" }}
            </v-btn>
            <v-icon
              v-if="properties.isUpdateable"
              small
              @click="modifyEntityAttributeDialog(properties)"
              class="edit-icon"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              v-if="properties.attributeName === 'Billing End Date'"
              small
              @click="remove(index)"
              class="delete-icon"
            >
              mdi-delete
            </v-icon>
          </v-card-actions>
        </v-card>
        <v-row dense justify="end">
          <v-col>
            <v-btn
              :color="colorTheme.button"
              dark
              @click="addEntityAttributeDialog"
            >
              Add Entity Specific Property
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import AddUpdateEntityAttribute from "@/components/add-update-entity/dialogs/AddUpdateEntityAttribute.vue";
import CellClipboard from "@/components/common/CellClipboard.vue";

export default {
  components: {
    AddUpdateEntityAttribute,
    CellClipboard
  },
  emits: ["selected-for-delete"],
  data: () => ({
    showList: [],
    attributeSelected: null,
    showAddUpdateEntityAttributeDialog: false
  }),
  computed: {
    ...mapState("modifyEntity", ["modifiedEntity"]),
    ...mapState("entityAttributes", [
      "modifiedEntityAttributes",
      "allowedValuesForAttributes",
      "advisoryFirmProperties",
    ]),
    ...mapState("userConfig", ["colorTheme"]),
    attributes() {
      // to do
      // different values based on type of modified entity
      if (this.modifiedEntityAttributes) {
        return this.orderAttributes(this.modifiedEntityAttributes);
      } else {
        return [];
      }
    },
    expandList() {
      return new Array(this.attributes.length).fill(false);
    }
  },
  watch: {
    expandList() {
      this.showList = Array(this.expandList.length).fill(false);
    }
  },
  methods: {
    orderAttributes(attributes) {
      let type = null;
      if (this.modifiedEntity) {
        type = this.modifiedEntity.type;
      }

      let orderedAttributes = [];
      let restOfAttributes = [];
      if (type === "ADVISORY_FIRM") {
        restOfAttributes = attributes.filter(
          attr => !this.advisoryFirmProperties.includes(attr.attributeName)
        );
      } else {
        restOfAttributes = attributes;
      }

      let allRulesForType = this.allowedValuesForAttributes.filter(
        rules => rules.entityType == type
      );
      allRulesForType.forEach(rules => {
        let filteredAttributes = restOfAttributes.filter(
          attr => attr.attributeName === rules.attributeName
        );
        restOfAttributes = restOfAttributes.filter(
          attr => attr.attributeName !== rules.attributeName
        );
        for (let i = 0; i < filteredAttributes.length; i++) {
          orderedAttributes.push(filteredAttributes[i]);
        }
      });
      restOfAttributes.sort((a, b) =>
        a.attributeName.toLowerCase() > b.attributeName.toLowerCase() ? 1 : -1
      );
      for (let i = 0; i < restOfAttributes.length; i++) {
        orderedAttributes.push(restOfAttributes[i]);
      }
      return orderedAttributes;
    },
    addEntityAttributeDialog() {
      this.showAddUpdateEntityAttributeDialog = true;
    },
    modifyEntityAttributeDialog(attributeForModification) {
      this.attributeSelected = attributeForModification;
      this.showAddUpdateEntityAttributeDialog = true;
    },
    remove(index) {
      let attributeForDeletion = this.attributes[index];
      this.$emit("selected-for-delete", attributeForDeletion);
    },
    toggleShow(index) {
      const currVal = this.showList[index];
      this.showList.splice(index, 1, !currVal);
    },
    showField(fieldName) {
      if (
        fieldName === "attributeName" ||
        fieldName === "attributeValue" ||
        fieldName === "createdAt" ||
        fieldName === "createdBy" ||
        fieldName === "updatedAt" ||
        fieldName === "updatedBy" ||
        fieldName === "isUpdateable"
      ) {
        return false;
      }
      return true;
    },
    timeline(properties) {
      let tmln = [];
      if (properties.updatedAt) {
        tmln.push({
          by: properties.updatedBy,
          time: properties.updatedAt,
          message: "Updated by",
          color: this.colorTheme.timelineLighten
        });
      }
      tmln.push({
        by: properties.createdBy,
        time: properties.createdAt,
        message: "Created by",
        color: this.colorTheme.timeline
      });
      return tmln;
    }
  }
};
</script>

<style scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
