<template>
  <v-container fluid>
    <error-dialog
      message="Update error! Default Billing Type not changed!"
      :showDialog.sync="showUpdateErrorDialog"
    ></error-dialog>
    <v-card class="ml-3 px-2 elevation-0">
      <v-row>
        <v-col cols="12">
          <v-card-title> Properties of an Advisory Firm </v-card-title>
        </v-col>
      </v-row>
      <v-container fluid>
        <v-card class="px-2 my-3 mb-3 elevation-2">
          <v-card-title class="pb-0 mb-0">
            <div>
              <div class="text-subtitle-1 font-weight-medium">
                Default Billing Type
              </div>
              <v-select
                class="pt-6 font-weight-regular"
                :menu-props="{ offsetY: true }"
                :color="colorTheme.dropDown"
                :item-color="colorTheme.dropDownItem"
                label="Set default billing type"
                :items="dropdownValues"
                v-model="attributeValue"
                outlined
                item-text="displayName"
                item-value="value"
                @change="changeDefaultBillingType"
                :disabled="disabledDefaultBillingType"
              ></v-select>
            </div>
          </v-card-title>
          <v-card-text class="pb-0">
            <v-expand-transition>
              <v-container v-show="showMoreInfo" class="pl-0 pt-0 ma-0">
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    v-for="(value, key) in properties"
                    v-bind:key="key"
                  >
                    <span v-if="value">
                      <v-text-field
                        v-if="showField(key)"
                        :label="key"
                        outlined
                        readonly
                        disabled
                        dense
                      >
                        <template v-slot:prepend-inner>
                          <cell-clipboard :text="value" />
                        </template>
                      </v-text-field>
                    </span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-timeline dense>
                      <v-timeline-item
                        small
                        v-for="item in timeline"
                        :key="item.time"
                        :color="item.color"
                      >
                        <div>
                          <div class="font-weight-normal">
                            {{ item.message }}
                            <strong>
                              {{ item.by }}
                            </strong>
                            @{{ item.time }}
                          </div>
                        </div>
                      </v-timeline-item>
                    </v-timeline>
                  </v-col>
                </v-row>
              </v-container>
            </v-expand-transition>
          </v-card-text>
          <v-card-actions>
            <v-btn text :color="colorTheme.button" @click="toggleShow">
              Show {{ showMoreInfo ? "Less" : "More" }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import { defaultBillingTypeEnabled } from "@/config/organization-config.js";
import { getRulesForAttribute } from "@/utils/entity-attributes.js";
import { mapActions, mapState } from "vuex";
import CellClipboard from "@/components/common/CellClipboard.vue";
import ErrorDialog from "@/components/dialogs/ErrorDialog.vue";

export default {
  components: {
    CellClipboard,
    ErrorDialog,
  },
  data: () => ({
    showMoreInfo: false,
    showUpdateErrorDialog: false,
  }),
  computed: {
    ...mapState("userConfig", ["colorTheme"]),
    ...mapState("entityAttributes", [
      "defaultBillingType",
      "allowedValuesForAttributes"
    ]),
    ...mapState("auth", ["user"]),
    disabledDefaultBillingType() {
      return !defaultBillingTypeEnabled(this.$cookies.get("organization"));
    },
    properties() {
      if (this.defaultBillingType) {
        return this.defaultBillingType.defaultBillingType;
      } else {
        return null;
      }
    },
    inherited() {
      if (this.defaultBillingType) {
        return this.defaultBillingType.inherited;
      } else {
        return false;
      }
    },
    attributeValue: {
      get() {
        if (this.defaultBillingType) {
          return this.defaultBillingType.defaultBillingType.attributeValue;
        } else {
          return "";
        }
      },
      set() {}
    },
    dropdownValues() {
      let rules = getRulesForAttribute(
        this.allowedValuesForAttributes,
        "Default Billing Type",
        "ADVISORY_FIRM"
      );
      if (rules) {
        let allowedValues = rules.allowedValues;
        if (allowedValues.length > 0) {
          let attributeValues = [];
          allowedValues.forEach(attrValue => {
            attributeValues.push({ displayName: attrValue, value: attrValue });
          });
          if (this.inherited) {
            let index = attributeValues.findIndex(
              attr => attr.value === this.attributeValue
            );

            if (index !== -1) {
              attributeValues[index] = {
                displayName: `Inherited (${this.attributeValue})`,
                value: this.attributeValue
              };
            }
          }
          return attributeValues;
        }
      }
      return [{ displayName: "Error", value: "Error" }];
    },
    timeline() {
      let properties = this.properties;
      let tmln = [];
      if (!properties) {
        return [];
      }
      if (properties.updatedAt) {
        tmln.push({
          by: properties.updatedBy,
          time: properties.updatedAt,
          message: "Updated by",
          color: this.colorTheme.timelineLighten
        });
      }
      if (properties.createdAt) {
        tmln.push({
          by: properties.createdBy,
          time: properties.createdAt,
          message: "Created by",
          color: this.colorTheme.timeline
        });
      }
      return tmln;
    },
  },
  methods: {
    ...mapActions("entityAttributes", [
      "addEntityAttribute",
      "updateEntityAttribute",
      "getDefaultBillingType",
    ]),
    toggleShow() {
      this.showMoreInfo = !this.showMoreInfo;
    },
    showField(fieldName) {
      if (fieldName === "id" || fieldName == "billingEntityId") {
        return true;
      } else {
        return false;
      }
    },
    async changeDefaultBillingType(newAttrValue) {
      let attrName = "Default Billing Type";
      let isUpdateable = true;
      let result;
      if (this.inherited || !this.defaultBillingType) { // inherited => own setting
        result = await this.addEntityAttribute({
          attributeName: attrName,
          attributeValue: newAttrValue,
          isUpdateable: isUpdateable
        });
      } else { // update firm setting
        result = await this.updateEntityAttribute({
          id: this.defaultBillingType.defaultBillingType.id,
          attributeName: attrName,
          attributeValue: newAttrValue,
          isUpdateable: isUpdateable
        });
      }
      if (result === "OK") {
        console.log("Successfully changed Default Billing Type");
      } else {
        this.showUpdateErrorDialog = true;
        console.log("Update error! Default Billing Type not changed!");
      }
      await this.getDefaultBillingType();
    }
  },
};
</script>

<style scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>
