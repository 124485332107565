<template>
  <v-container>
    <confirmation-dialog
      :showDialog.sync="show"
      message="Are you sure you want to delete this group?"
      @confirm="confirm"
      @cancel="cancel"
    ></confirmation-dialog>
    <running-dialog
      message="Deleting group. Please wait..."
      :showDialog.sync="deleting"
    ></running-dialog>
    <error-dialog
      message="Error!"
      :showDialog.sync="showDeleteGroupErrorDialog"
    ></error-dialog>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog.vue";
import RunningDialog from "@/components/dialogs/RunningDialog.vue";
import ErrorDialog from "@/components/dialogs/ErrorDialog.vue";
export default {
  components: {
    ConfirmationDialog,
    RunningDialog,
    ErrorDialog
  },
  props: ["showDialog", "groupToDelete"],
  emits: ["update:showDialog", "update:groupToDelete"],
  data: () => ({
    deleting: false,
    showDeleteGroupErrorDialog: false
  }),
  computed: {
    ...mapState("userConfig", ["colorTheme"]),
    show: {
      get() {
        return this.showDialog;
      },
      set(newValue) {
        this.$emit("update:showDialog", newValue);
      }
    }
  },
  methods: {
    ...mapActions("directoryNavigation", ["jumpToNavigationItem"]),
    ...mapActions("modifyEntityGroup", ["deleteGroup"]),
    ...mapActions("entityActions", ["setTab"]),
    async confirm() {
      if (this.groupToDelete !== null) {
        this.deleting = true;
        let result = await this.deleteGroup(this.groupToDelete.id);
        this.deleting = false;
        if (result) {
          await this.jumpToNavigationItem(null);
          await this.setTab("HierarchyTree");
          this.cancel();
        } else {
          this.showDeleteGroupErrorDialog = true;
        }
      }
    },
    cancel() {
      this.$emit("update:showDialog", false);
      this.$emit("update:groupToDelete", null);
    }
  }
};
</script>
